import React, { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useAuth, useFetchFacilities, useIsTestDataCreator, useState, useUIStore, useZccService } from 'utils/hooks';
import { ZCCApi, MedicalApi } from 'apis';
import { PatientHoldKeys, PatientHoldStatus, ServiceRequestStatusType } from 'apis/medical';
import { ZccReferralStatus, ZccReferralType, ZCCSortByParameters, ZccRefererType } from 'apis/zcc';
import { SectionLayout, Table, Button, FilterButtonV3, IconMenu, Icon, Tooltip } from 'components';
import ServiceRequestSidePanel from 'components/sidePanels/ServiceRequest';
import { CircleCrossedBoldIcon } from '@zeel-dev/zeel-ui';
import moment from 'moment';
import cn from 'classnames';
import { getCalendarTime, joinString } from 'utils/helper';

import styles from './style.module.scss';
import { FilterType } from 'components/FilterButtonV3/FilterButtonV3.types';

enum ReferralActions {
  RevokeReferral = 'revoke_referral',
  UpdateExpirationDate = 'update_expiration_date',
  ReactivateReferral = 'reactivate_referral',
  PutPatientOnHold = 'put_patient_on_hold',
}

enum ReferralColumn {
  PATIENT_NAME = 'patient_name',
  VA_REFERRAL_ID = 'va_referral_id',
  VA_FACILITY_NAME = 'va_facility_name',
  ASSIGNED_PROVIDER = 'assigned_provider',
  // DATE_CREATED = 'date_created',
  INGESTION_DATE = 'ingestion_date',
  EXPIRATION_DATE = 'expiration_date',
  STATUS = 'status',
  NOTES = 'notes',
  RFS = 'rfs',
  ACTION = 'action',
  REFERER = 'referring_organization',
}

const statusIconMap: Record<ZccReferralStatus, string> = {
  [ZccReferralStatus.DRAFT]: 'edit-pencil',
  [ZccReferralStatus.ACTIVE]: 'checkmark-circle',
  [ZccReferralStatus.REVOKED]: 'x-circle',
  [ZccReferralStatus.COMPLETED]: 'calendar-checkmark',
  [ZccReferralStatus.EXPIRED]: 'calendar-x',
  [ZccReferralStatus.MEDICAL_HOLD]: 'calendar-appointment-pause',
  [ZccReferralStatus.SCHEDULING_HOLD]: 'calendar-appointment-pause',
  [ZccReferralStatus.PATIENT_REVIEW_HOLD]: 'calendar-appointment-pause',
};

const statusLabelMap: Record<ZccReferralStatus, string> = {
  [ZccReferralStatus.DRAFT]: 'Draft',
  [ZccReferralStatus.ACTIVE]: 'Active',
  [ZccReferralStatus.REVOKED]: 'Revoked',
  [ZccReferralStatus.COMPLETED]: 'Completed',
  [ZccReferralStatus.EXPIRED]: 'Expired',
  [ZccReferralStatus.MEDICAL_HOLD]: 'Medical Hold',
  [ZccReferralStatus.SCHEDULING_HOLD]: 'Scheduling Hold',
  [ZccReferralStatus.PATIENT_REVIEW_HOLD]: 'Patient Review Hold',
};

const exceptionFilters = [
  {
    id: 'needs_consent',
    label: 'Needs Consent',
    key: 'consents_completed',
    value: false,
  },
  {
    id: 'scheduling_hold',
    label: 'Scheduling Hold',
    key: 'status',
    value: [ZccReferralStatus.SCHEDULING_HOLD],
  },
  {
    id: 'medical_hold',
    label: 'Medical Hold',
    key: 'status',
    value: [ZccReferralStatus.MEDICAL_HOLD],
  },
  {
    id: 'trust_and_safety_hold',
    label: 'Patient Review Hold',
    key: 'status',
    value: [ZccReferralStatus.PATIENT_REVIEW_HOLD],
  },
];

const defaultFilters = {
  test_data: false,
};

export default function ReferralsV2() {
  const isTestDataCreator = useIsTestDataCreator();

  const service = useZccService<ZccReferralType>(ZCCApi.fetchReferralItems, {
    initialParameters: {
      sortBy: {
        ingestion_date: 'desc',
      },
      filters: defaultFilters,
    },
    exceptions: exceptionFilters,
    paramTypes: {
      office_based_referral: 'boolean',
      consents_completed: 'boolean',
      new_referral: 'boolean',
      first_referral: 'boolean',
      rfs_needed: 'boolean',
      rfs_submitted: 'boolean',
      test_data: 'boolean',
    },
    transformRequest: (params) => {
      if ((!isTestDataCreator && params.filters) || params.filters?.test_data === false) {
        delete params.filters.test_data;
      }
      return params;
    },
  });

  const [open, setOpen] = useState<boolean>(false);
  const [activeRowData, setActiveRowData] = useState<any>(null);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();
  const { loading: loadingFacilities, facilities } = useFetchFacilities();

  const { openModal, openAlert } = useUIStore();
  const { user } = useAuth();

  const rowClicked = (row: any) => {
    const { id } = row || {};
    if (open && activeRowData && id === activeRowData?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRowData(row);
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: ZCCSortByParameters = {};
    sortModel?.forEach(({ field, sort }: any) => {
      sortBy[field] = sort;
    });
    if (!isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  const highlightRow = (rowId: string) => {
    setHighlightedRowId(rowId);
    setTimeout(() => setHighlightedRowId(null), 5000);
  };

  const rows = useMemo(() => {
    const r = [
      ...(service.data || []).map((item: ZccReferralType, index: number) => {
        const {
          _id,
          actions,
          assigned_provider,
          // date_created,
          ingestion_date,
          expiration_date,
          patient_id,
          patient_name,
          patient_dob,
          patient_ssn,
          patient_network_status,
          status,
          hold_status_date,
          condition_type,
          va_facility_name,
          referring_organization,
          va_referral_id,
          main_note,
          action_note,
          office_based_referral,
          rfs_needed,
          rfs_needed_reason,
          rfs_submitted,
        } = item;

        const mainNote = main_note
          ? `${joinString(
              [getCalendarTime(main_note.timestamp, true, 'MMM D', true), main_note.pcc_agent || ''],
              ', '
            )}: ${main_note.text || ''}`
          : '';
        const actionNote = action_note?.[0]
          ? `${joinString(
              [getCalendarTime(action_note[0].timestamp, true, 'MMM D', true), action_note[0].pcc_agent || ''],
              ', '
            )}: ${action_note[0].text || ''}`
          : '';

        return {
          id: index,
          entity_id: _id.toString(),
          actions,
          // date_created,
          ingestion_date,
          expiration_date,
          patient_id,
          patient_name,
          patient_dob,
          patient_ssn,
          patient_network_status,
          status,
          hold_status_date,
          va_facility_name,
          referring_organization,
          assigned_provider,
          va_referral_id,
          condition_type,
          data: item,
          main_note: mainNote,
          office_based_referral,
          action_note: actionNote,
          rfs_needed,
          rfs_needed_reason,
          rfs_submitted,
        };
      }),
    ];
    return r;
  }, [service.data]);

  // Actions
  const revokeReferral = (serviceRequestId: string) => {
    openModal({
      id: 'revoke-referral',
      props: {
        serviceRequestId,
      },
      callback: (response: any) => {
        if (response) {
          service.refresh();
        }
      },
    });
  };

  const reActivateReferral = async (serviceRequestId: string) => {
    try {
      await MedicalApi.updateServiceRequest(serviceRequestId, {
        status: ServiceRequestStatusType.Active,
      });
      openAlert({ title: `Re-activated the referral successfully`, severity: 'success' });
      service.refresh();
    } catch {
      openAlert({ title: `Failed to re-activate the referral`, severity: 'error' });
    }
  };

  const openUpdateReferralExpirationModal = async (serviceRequestId: string, expirationDate: string) => {
    openModal({
      id: 'update-referral-expiration-date',
      props: {
        serviceRequestId,
        expirationDate,
      },
      callback: (response: any) => {
        if (response) {
          service.refresh();
        }
      },
    });
  };

  const openUploadConsentModal = (patientId: string, serviceRequestId: string) => {
    openModal({
      id: 'upload-consent',
      props: {
        patientId,
        serviceRequestId,
      },
      callback: (response: any) => {
        if (response) {
          service.refresh();
        }
      },
    });
  };

  const openPatientModal = async (patientId: string) => {
    openModal({
      id: 'patient',
      props: {
        patientId,
      },
    });
  };

  const openPutPatientOnHoldModal = async (row: any) => {
    const { patient_id: patientId, patient_name: patientName } = row;
    openModal({
      id: 'put-patient-on-hold',
      props: {
        patientId,
        patientName,
      },
      callback: (res: boolean) => {
        if (res) {
          putActionNoteForOnHold(row, res);
        }
      },
    });
  };

  const openRemovePatientHoldModal = async (row: any) => {
    const { patient_id: patientId, patient_name: patientName, status } = row;
    openModal({
      id: 'remove-patient-hold',
      props: {
        patientId,
        patientName,
        holdType: statusLabelMap[status as ZccReferralStatus],
      },
      callback: ({ shouldRemove, reason }) => {
        if (shouldRemove) {
          putActionNoteForRemovingHold(row, reason);
        }
      },
    });
  };

  const addActionNoteForHold = async (row: any, text: string) => {
    const { patient_id: patientId, entity_id: serviceRequestId } = row;
    if (!patientId || !serviceRequestId) return;

    const zccReferral = await ZCCApi.fetchZccReferral(serviceRequestId);

    const noteAction = zccReferral.actionNote?.length ? ZCCApi.updateNote : ZCCApi.createNote;

    await noteAction({
      note: [
        {
          text,
          pcc_agent: user?.pccAgentName,
        },
        ...(zccReferral.actionNote || []),
      ],
      noteType: 'action',
      patientId,
    });
  };

  const putActionNoteForOnHold = async (row: any, res: any) => {
    const { patient_name: patientName } = row;
    const { hold_status, hold_status_reason, hold_status_date } = res;

    const text = `${PatientHoldStatus[hold_status as PatientHoldKeys]} - ${
      hold_status_date ? moment(hold_status_date).format('MM/DD/YYYY') : 'indefinite'
    }${hold_status_reason ? ` - ${hold_status_reason}` : ''}`;

    try {
      await addActionNoteForHold(row, text);

      openAlert({
        title: `Patient on Hold${hold_status_date ? '' : ' Indefinitely'}`,
        description: `You'll need to manually resume scheduling for ${patientName}`,
      });
      service.refresh();
    } catch {
      openAlert({ title: `Failed to save the action note`, severity: 'error' });
    }
  };

  const putActionNoteForRemovingHold = async (row: any, reason?: string) => {
    const text = reason ? `Patient Hold Removed - ${reason}` : 'Patient Hold Removed';

    try {
      await addActionNoteForHold(row, text);

      openAlert({
        title: 'Patient Hold Removed',
      });
      service.refresh();
    } catch {
      openAlert({ title: `Failed to save the action note`, severity: 'error' });
    }
  };

  const columns = useMemo(
    () => [
      {
        id: ReferralColumn.PATIENT_NAME,
        label: 'Patient Name',
        width: 215,
        renderCell: ({ row }: any) => {
          const patientDob = row?.patient_dob ? moment(row?.patient_dob).format('MM/DD/YYYY') : '';
          return (
            <div className={styles.referralCellWrapper}>
              <span className={cn(styles.patientName, styles.ellipsisText)}>{row?.patient_name}</span>
              <Tooltip
                title={
                  <>
                    <p className={styles.tooltip}>{`DOB ${patientDob}`}</p>
                    <p className={styles.tooltip}>SSN: *{row?.patient_ssn}</p>
                  </>
                }
                placement='top'>
                <span>
                  <Icon name='information' size={16} />
                </span>
              </Tooltip>
              {row?.patient_network_status === 'REMOVED FROM NETWORK' && <CircleCrossedBoldIcon color='error' />}
            </div>
          );
        },
      },
      { id: ReferralColumn.VA_REFERRAL_ID, label: 'Referral #', width: 200 },
      {
        id: ReferralColumn.REFERER,
        label: 'Referer',
        width: 100,
      },
      {
        id: ReferralColumn.VA_FACILITY_NAME,
        label: 'VA Facility Name',
        width: 250,
        renderCell: ({ value }: any) => {
          return (
            <Tooltip title={<span className={styles.tooltip}>{value}</span>} placement='top'>
              <span className={styles.ellipsisText}>{value}</span>
            </Tooltip>
          );
        },
      },
      {
        id: ReferralColumn.ASSIGNED_PROVIDER,
        label: 'Assigned Provider',
        width: 200,
        renderCell: ({ row }: any) => {
          return (
            <div className={cn(styles.referralCellWrapper, styles.ellipsisText)}>
              <span>
                <Icon
                  name={row?.office_based_referral ? 'building' : 'house'}
                  title={row?.office_based_referral ? 'In-Office' : 'In-Home'}
                  size={16}
                />
              </span>
              <span className={styles.ellipsisText}>{row?.assigned_provider}</span>
            </div>
          );
        },
      },
      {
        id: ReferralColumn.STATUS,
        label: 'Status',
        width: 200,
        renderCell: ({ row }: any) => {
          return (
            <div className={styles.statusCellWrapper}>
              <div className={cn(styles.referralCellWrapper, styles[row?.status?.toLowerCase()])}>
                <Icon name={statusIconMap[row?.status as ZccReferralStatus]} size={16} />
                <span>{statusLabelMap[row?.status as ZccReferralStatus] ?? row?.status}</span>
              </div>
              {(row?.status === ZccReferralStatus.MEDICAL_HOLD ||
                row?.status === ZccReferralStatus.SCHEDULING_HOLD ||
                row?.status === ZccReferralStatus.PATIENT_REVIEW_HOLD) && (
                <div className={styles.holdDate}>
                  {row?.hold_status_date ? `until ${getCalendarTime(row.hold_status_date, false)}` : 'Indefinite'}
                </div>
              )}
            </div>
          );
        },
      },
      // {
      //   id: ReferralColumn.DATE_CREATED,
      //   label: 'Created',
      //   width: 130,
      //   type: 'moment',
      //   cellClassName: styles.createdDateCell,
      //   renderCell: ({ row }: any) => {
      //     const momentDate = moment(row?.date_created);
      //     if (!row?.date_created || !momentDate?.isValid()) return '-';
      //     const daysSinceReferralAcceptance = moment().diff(row?.date_created, 'days');
      //     return (
      //       <div className={styles.dateCreatedCellWrapper}>
      //         <div className={styles.createdDate}>
      //           <span>{momentDate.format('MM/DD/YY')}</span>
      //           <span>-</span>
      //         </div>
      //         <p className={cn(styles.secondLine, styles.aging)}>Accepted {daysSinceReferralAcceptance} days ago</p>
      //       </div>
      //     );
      //   },
      // },
      {
        id: ReferralColumn.INGESTION_DATE,
        label: 'Date Ingested',
        width: 130,
        type: 'moment',
        cellClassName: styles.createdDateCell,
        renderCell: ({ row }: any) => {
          const momentDate = moment(row?.ingestion_date);
          if (!row?.ingestion_date || !momentDate?.isValid()) return '-';
          const daysSinceReferralIngested = moment().endOf('day').diff(row?.ingestion_date, 'days');
          const agingText =
            daysSinceReferralIngested < 1
              ? 'Ingested today'
              : daysSinceReferralIngested < 2
              ? 'Ingested yesterday'
              : `Ingested ${daysSinceReferralIngested} days ago`;
          return (
            <div className={styles.dateCreatedCellWrapper}>
              <div className={styles.createdDate}>
                <span>{momentDate.format('MM/DD/YY')}</span>
                <span>-</span>
              </div>
              <p className={cn(styles.secondLine, styles.aging)}>{agingText}</p>
            </div>
          );
        },
      },
      {
        id: ReferralColumn.EXPIRATION_DATE,
        label: 'Expires',
        width: 125,
        type: 'moment',
        renderCell: ({ row }: any) => {
          const momentDate = moment(row?.expiration_date);
          if (!row?.expiration_date || !momentDate?.isValid()) return '-';
          return (
            <div className={styles.dateCreatedCellWrapper}>
              <span>{momentDate.format('MM/DD/YY')}</span>
              {row?.condition_type === 'Acute' && (
                <Tooltip title={<span className={styles.tooltip}>Acute Referral</span>} placement='top'>
                  <span className={styles.icon}>
                    <Icon name='stopwatch' size={16} />
                  </span>
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        id: ReferralColumn.NOTES,
        label: 'Notes',
        width: 280,
        cellClassName: styles.notes,
        sortable: false,
        renderCell: ({ row }: any) => {
          return (
            <div className={styles.ellipsisText}>
              {row?.main_note && <p className={cn(styles.mainNote, styles.ellipsisText)}>{row.main_note}</p>}
              {row?.action_note && <p className={cn(styles.secondLine, styles.ellipsisText)}>{row.action_note}</p>}
            </div>
          );
        },
      },
      {
        id: ReferralColumn.RFS,
        label: 'RFS',
        width: 250,
        cellClassName: styles.notes,
        sortable: false,
        renderCell: ({ row }: any) => {
          const rfsIndicated = row.rfs_needed === true ? 'Yes' : row.rfs_needed === false ? 'No' : 'Not answered';
          const rfsNeededReason = row.rfs_needed === false ? row.rfs_needed_reason || '' : '';
          const rfsSubmitted =
            row.rfs_submitted === true ? 'RFS submitted' : row.rfs_submitted === false ? 'RFS not submitted ' : '';
          return (
            <div className={styles.ellipsisText}>
              <p className={cn(styles.secondLine, styles.ellipsisText)}>RFS Indicated: {rfsIndicated}</p>
              {rfsNeededReason && (
                <Tooltip title={<span className={styles.tooltip}>Reason: {rfsNeededReason}</span>} placement='top'>
                  <p className={cn(styles.secondLine, styles.ellipsisText)}>Reason: {rfsNeededReason}</p>
                </Tooltip>
              )}
              {rfsSubmitted && <p className={cn(styles.secondLine, styles.ellipsisText)}>{rfsSubmitted}</p>}
            </div>
          );
        },
      },
      {
        id: ReferralColumn.ACTION,
        label: ' ',
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }: any) => {
          const actionItems = [];
          if (row.actions?.includes(ReferralActions.UpdateExpirationDate)) {
            actionItems.push({
              icon: 'calendar-empty',
              label: 'Update Expiration Date',
              onClick: () => openUpdateReferralExpirationModal(row?.entity_id, row?.expiration_date),
            });
          }
          if (row.actions?.includes(ReferralActions.RevokeReferral)) {
            actionItems.push({
              icon: 'x-circle',
              label: 'Revoke Referral',
              severity: 'danger',
              onClick: () => revokeReferral(row?.entity_id),
            });
          }
          if (row.actions?.includes(ReferralActions.ReactivateReferral)) {
            actionItems.push({
              icon: 'refresh',
              label: 'Re-Activate Referral',
              onClick: () => reActivateReferral(row?.entity_id),
            });
          }
          if (row.consents_completed === 'No') {
            actionItems.push({
              icon: 'upload',
              label: 'Upload Consent',
              onClick: () => openUploadConsentModal(row?.patient_id, row?.entity_id),
            });
          }
          if (
            row.status !== ZccReferralStatus.MEDICAL_HOLD &&
            row.status !== ZccReferralStatus.SCHEDULING_HOLD &&
            row.status !== ZccReferralStatus.PATIENT_REVIEW_HOLD
          ) {
            actionItems.push({
              icon: 'calendar-appointment-pause',
              label: 'Put Patient on Hold',
              onClick: () => openPutPatientOnHoldModal(row),
            });
          } else {
            actionItems.push({
              icon: 'calendar-checkmark',
              label: 'Remove Patient Hold',
              onClick: () => openRemovePatientHoldModal(row),
            });
          }
          actionItems.push({
            icon: 'user',
            label: 'Open Patient',
            newSection: actionItems.length > 0,
            onClick: () => openPatientModal(row?.patient_id),
          });

          return (
            <div className={styles.actions}>
              <IconMenu items={actionItems} />
            </div>
          );
        },
      },
    ],
    []
  );

  const openAddReferralModal = () => {
    openModal({
      id: 'add-referral',
      callback: (response: any) => {
        if (response) {
          service.refresh();
        }
      },
    });
  };

  const sideContent = (
    <ServiceRequestSidePanel
      open={open}
      id={activeRowData?.entity_id}
      onClose={() => setOpen(false)}
      onChange={() => {
        highlightRow(activeRowData?.id);
        service.refresh();
      }}
    />
  );

  return (
    <SectionLayout
      alert={service.error}
      title='Filtered Referrals'
      sideContent={sideContent}
      titleContent={
        <Button variant='outlined' color='primary' size='large' onClick={openAddReferralModal}>
          Add New Referral
        </Button>
      }>
      <div className={styles.tableHeader}>
        <FilterButtonV3
          initialValue={service.parameters.filters}
          defaultFilters={defaultFilters}
          onChange={(newFilters: any) => {
            if (newFilters.consents_completed) {
              newFilters.consents_missing = [];
            }
            service.setFilters(newFilters);
          }}
          searchTestDataOnly={isTestDataCreator && service.parameters.filters?.test_data}
          searches={[
            {
              id: 'patient_name',
              label: 'Patient Name',
              type: 'auto-complete-search',
              fetchItems: ZCCApi.searchPatientName,
            },
            { id: 'va_referral_id', label: 'Referral #', type: 'text' },
            {
              id: 'assigned_provider',
              label: 'Assigned Provider',
              type: 'auto-complete-search',
              fetchItems: ZCCApi.searchPractitionerName,
            },
          ]}
          exceptions={exceptionFilters}
          filters={[
            {
              id: 'condition_type',
              label: 'Referral Type',
              type: 'select',
              options: [
                { label: 'All', value: '' },
                { label: 'Acute', value: 'Acute' },
                { label: 'Chronic', value: 'Chronic' },
                { label: 'Initial Care', value: 'Initial Care' },
                { label: 'Continuation Care', value: 'Continuation Care' },
              ],
            },
            {
              id: 'first_referral',
              label: 'New Patient Referral',
              type: 'segments',
              options: [
                { label: 'New', value: true },
                { label: 'All', value: '' },
                { label: 'Subsequent', value: false },
              ],
            },
            {
              id: 'new_referral',
              label: 'Referral Booking Status',
              type: 'segments',
              options: [
                { label: 'New', value: true },
                { label: 'All', value: '' },
                { label: 'Existing', value: false },
              ],
            },
            {
              id: 'status',
              label: 'Status',
              type: 'select-multiple',
              options: [
                { label: 'DRAFT', value: ZccReferralStatus.DRAFT },
                { label: 'ACTIVE', value: ZccReferralStatus.ACTIVE },
                { label: 'COMPLETED', value: ZccReferralStatus.COMPLETED },
                { label: 'REVOKED', value: ZccReferralStatus.REVOKED },
                { label: 'EXPIRED', value: ZccReferralStatus.EXPIRED },
              ],
            },
            {
              id: 'referring_organization',
              label: 'Referer',
              type: 'select',
              options: [
                { label: 'Optum', value: ZccRefererType.OPTUM },
                { label: 'Triwest', value: ZccRefererType.TRIWEST },
                { label: 'AHF', value: ZccRefererType.AHF },
              ],
            },
            {
              id: 'office_based_referral',
              label: 'Location Type',
              type: 'segments',
              options: [
                { label: 'In-Home', value: false },
                { label: 'All', value: '' },
                { label: 'In-Office', value: true },
              ],
            },
            {
              id: 'rfs_needed',
              label: 'RFS Indicated',
              type: 'segments',
              options: [
                { label: 'Yes', value: true },
                { label: 'Not Set', value: '' },
                { label: 'No', value: false },
              ],
            },
            {
              id: 'rfs_submitted',
              label: 'RFS Submitted',
              type: 'segments',
              options: [
                { label: 'Yes', value: true },
                { label: 'All', value: '' },
                { label: 'No', value: false },
              ],
            },
            {
              id: 'rfs_needed',
              label: 'RFS Indicated',
              type: 'segments',
              options: [
                { label: 'Yes', value: true },
                { label: 'Not Set', value: '' },
                { label: 'No', value: false },
              ],
            },
            {
              id: 'rfs_submitted',
              label: 'RFS Submitted',
              type: 'segments',
              options: [
                { label: 'Yes', value: true },
                { label: 'All', value: '' },
                { label: 'No', value: false },
              ],
            },
            {
              id: 'va_facility_name',
              label: 'VAMC',
              placeholder: 'Search VAMC',
              type: 'auto-complete-multiple',
              maxSelections: 10,
              maxSelectionsHelperText: 'Maximum VAMC selections reached.',
              options: facilities,
              loading: loadingFacilities,
            },
            ...(isTestDataCreator
              ? [
                  {
                    id: 'test_data',
                    label: 'Test Data',
                    type: 'segments',
                    permanent: true,
                    options: [
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ],
                  } as FilterType,
                ]
              : []),
          ]}
        />
      </div>
      <Table
        className={styles.table}
        loading={service.loading}
        name={`${rows.length} Referrals`}
        tableCellClassName={styles.tableCellClassName}
        columns={columns}
        rows={rows}
        rowHeight={76}
        onRowClick={(row: any) => rowClicked(row)}
        activeRowId={open ? activeRowData?.id : undefined}
        highlightedRowId={highlightedRowId}
        page={service.parameters.page}
        pageSize={service.parameters.pageSize}
        serverPagination
        serverRowCount={service.total}
        onPageChange={(page: any) => service.setPage(page)}
        onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
        rowsPerPageOptions={[100]}
        sortModel={service.sortModel}
        onSortModelChange={sortChangeHandler}
        rowClassName={styles.row}
      />
    </SectionLayout>
  );
}
